// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-achizitii-seap-js": () => import("./../../../src/pages/achizitii-seap.js" /* webpackChunkName: "component---src-pages-achizitii-seap-js" */),
  "component---src-pages-acord-prelucrare-datelor-js": () => import("./../../../src/pages/acord-prelucrare-datelor.js" /* webpackChunkName: "component---src-pages-acord-prelucrare-datelor-js" */),
  "component---src-pages-afiliere-js": () => import("./../../../src/pages/afiliere.js" /* webpackChunkName: "component---src-pages-afiliere-js" */),
  "component---src-pages-angajament-de-calitate-sla-js": () => import("./../../../src/pages/angajament-de-calitate-sla.js" /* webpackChunkName: "component---src-pages-angajament-de-calitate-sla-js" */),
  "component---src-pages-colocare-servere-js": () => import("./../../../src/pages/colocare-servere.js" /* webpackChunkName: "component---src-pages-colocare-servere-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gazduire-magento-ssd-js": () => import("./../../../src/pages/gazduire-magento-ssd.js" /* webpackChunkName: "component---src-pages-gazduire-magento-ssd-js" */),
  "component---src-pages-gazduire-prestashop-ssd-js": () => import("./../../../src/pages/gazduire-prestashop-ssd.js" /* webpackChunkName: "component---src-pages-gazduire-prestashop-ssd-js" */),
  "component---src-pages-gazduire-reseller-ssd-js": () => import("./../../../src/pages/gazduire-reseller-ssd.js" /* webpackChunkName: "component---src-pages-gazduire-reseller-ssd-js" */),
  "component---src-pages-gazduire-web-ssd-js": () => import("./../../../src/pages/gazduire-web-ssd.js" /* webpackChunkName: "component---src-pages-gazduire-web-ssd-js" */),
  "component---src-pages-gazduire-wordpress-ssd-js": () => import("./../../../src/pages/gazduire-wordpress-ssd.js" /* webpackChunkName: "component---src-pages-gazduire-wordpress-ssd-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inregistrare-domenii-js": () => import("./../../../src/pages/inregistrare-domenii.js" /* webpackChunkName: "component---src-pages-inregistrare-domenii-js" */),
  "component---src-pages-parteneri-js": () => import("./../../../src/pages/parteneri.js" /* webpackChunkName: "component---src-pages-parteneri-js" */),
  "component---src-pages-politica-de-confidentialitate-js": () => import("./../../../src/pages/politica-de-confidentialitate.js" /* webpackChunkName: "component---src-pages-politica-de-confidentialitate-js" */),
  "component---src-pages-servere-dedicate-js": () => import("./../../../src/pages/servere-dedicate.js" /* webpackChunkName: "component---src-pages-servere-dedicate-js" */),
  "component---src-pages-servere-vps-js": () => import("./../../../src/pages/servere-vps.js" /* webpackChunkName: "component---src-pages-servere-vps-js" */),
  "component---src-pages-status-js": () => import("./../../../src/pages/status.js" /* webpackChunkName: "component---src-pages-status-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-pages-transfer-domenii-js": () => import("./../../../src/pages/transfer-domenii.js" /* webpackChunkName: "component---src-pages-transfer-domenii-js" */),
  "component---src-templates-blog-categories-js": () => import("./../../../src/templates/blog-categories.js" /* webpackChunkName: "component---src-templates-blog-categories-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */)
}

